@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--titles-bg {
  overflow: hidden;

  > .container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

.titles-bg {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 27.25rem;
    max-width: 72.625rem;
    margin: 0 auto;

    @include media-breakpoint-down(xl) {
      min-height: 380px;
    }

    @include media-breakpoint-down(lg) {
      min-height: 380px;
    }

    @include media-breakpoint-down(md) {
      min-height: 240px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 45vw;
    }

    @include media-breakpoint-down(sm) {
      padding-right: $container-padding-x / 2;
      padding-left: $container-padding-x / 2;
    }
  }

  &__images {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;

    &--d:first-child:not(:only-child) {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--m {
      display: none;
      width: 100%;

      img {
        top: 50%;
        left: 50%;
        height: auto;
        transform: translate(-50%, -50%);
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  &__text {
    position: relative;
    display: block;
    z-index: 2;
    max-width: 51rem;
    margin: 0 0 11%;

    @include media-breakpoint-down(xl) {
      margin-bottom: 11%;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 5%;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    &-line {
      display: block;
    }

    &--first {
      max-width: 75%;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    &--second {
      display: flex;
      flex-direction: row-reverse;

      @include media-breakpoint-down(sm) {
        display: inline-block;
        max-width: 90%;
      }
    }

    .star-icon {
      padding-top: 0.9em;
      margin-right: 15%;
      transform-origin: center;
      animation: star-blinking 4s infinite ease-in-out;

      @include media-breakpoint-down(xl) {
        margin-right: 10%;
      }

      @include media-breakpoint-down(md) {
        margin-right: 5%;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
        float: left;
        padding-top: 0.25em;
      }

      svg {
        width: 1em;
        height: 1em;

        @include media-breakpoint-down(sm) {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

@include color-mode(dark) {
  .section--titles-bg {
    border-bottom: 1px solid $border-color;
  }
}
